



































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import FileInput from '@/app/ui/components/FileInput/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import IconDownload from '@/app/ui/assets/icon_download.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import controller from '@/app/ui/controllers/HomepageBannerController'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  and,
  or,
  url as urlValidation,
} from 'vuelidate/lib/validators'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import dayjs from 'dayjs'
import { HomepageBanner } from '@/domain/entities/HomepageBanner'

interface Form {
  title: string
  image: Blob | File | null
  urlLink: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    TextInput,
    FileInput,
    Modal,
    LoadingOverlay,
    DateTimePicker,
    IconDownload,
    IconWarningTriangle
  },
})
export default class EditHomepageBannerPage extends Vue {
  confirmationModal = false
  successModal = false
  imagePreview = ''

  form: Form = {
    title: '',
    image: null,
    urlLink: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  }

  created(): void {
    EventBus.$on(EventBusConstants.UPDATE_HOMEPAGE_BANNER_SUCCESS, () => {
      this.confirmationModal = false
      this.successModal = true
    })

    controller.getHomepageBannerDetail(Number(this.$route.params.id))
  }

  get bannerDetail(): HomepageBanner {
    return controller.homepageBannerDetail
  }

  @Watch('bannerDetail')
  updateForm(bannerDetail: HomepageBanner): void {
    if (
      bannerDetail.status &&
      !['Active', 'Scheduled'].includes(bannerDetail.status)
    ) {
      this.$router.push({ name: 'HomepageBannerList' })
    }

    this.form = {
      title: bannerDetail.title as string,
      image: null,
      urlLink: bannerDetail.urlLink as string,
      startDate: dayjs(bannerDetail.startTime as string).format('YYYY-MM-DD'),
      startTime: dayjs(bannerDetail.startTime as string).format('HH:mm:ss'),
      endDate: dayjs(bannerDetail.endTime as string).format('YYYY-MM-DD'),
      endTime: dayjs(bannerDetail.endTime as string).format('HH:mm:ss'),
    }

    this.imagePreview = bannerDetail.imageUrl as string
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  private changeImage(file: File) {
    this.form.image = file
  }

  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        title: {
          required,
          maxLength: maxLength(70),
        },
        urlLink: {
          url: and(
            required,
            or(urlValidation, (value: string) =>
              value.startsWith('lionparcel://app')
            )
          ),
        },
        startDate: { required },
        endDate: { required },
        startTime: { required },
        endTime: { required },
      },
    }
  }

  private updateHomepageBanner() {
    controller.updateHomepageBanner({
      id: Number(this.$route.params.id),
      title: this.form.title,
      image: this.form.image,
      urlLink: this.form.urlLink,
      startTime: dayjs(`${this.form.startDate} ${this.form.startTime}`).unix(),
      endTime: dayjs(`${this.form.endDate} ${this.form.endTime}`).unix(),
    })
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({ name: 'HomepageBannerList' })
  }

  get today(): string {
    return dayjs().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
  }

  get endMinDate(): string {
    const startDate = dayjs(this.form.startDate)
    const today = dayjs()

    if (startDate.isBefore(today)) {
      return today.format()
    }

    return startDate.format()
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UPDATE_HOMEPAGE_BANNER_SUCCESS)
  }
}
